<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/skipshare">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Add Skip</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
            <b-form v-on:submit.prevent="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-3"
                label="Customer:"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  placeholder="Select customer"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }}({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Space:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="space"
                  :options="spaces"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Size:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="size"
                  :options="sizes"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Expiry Date:"
                label-for="input-2"
              >
                <b-form-datepicker
                  :min="new Date().toISOString().substr(0, 10)"
                  v-model="expirydate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Post Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  @keyup="isZipCodeValid"
                  type="text"
                  required
                  placeholder="Enter post Code"
                ></b-form-input>
              </b-form-group>
              <div
                v-show="!this.zipcodeValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Zip Code
              </div>

              <b-form-group
                id="input-group-2"
                :label="
                  'Price for Skip: £ (Skipshare portion (' +
                    this.skipSharePercentage +
                    '%) will be added)'
                "
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="price"
                  @keypress="onlyForCurrency"
                  required
                  placeholder="Enter price"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  placeholder="Enter description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Instruction:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="instruction"
                  placeholder="Enter instruction here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Cover Image:"
                label-for="input-2"
              >
                <input
                  type="file"
                  @change="coverImageShow"
                  required
                /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="coverimageUrl"
                  :src="coverimageUrl"
                />
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Images:"
                label-for="input-2"
              >
                <input
                  type="file"
                  ref="file"
                  multiple="multiple"
                  @change="submitFiles"
                />
              </b-form-group>

              <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
                <b-row>
                  <b-col-4
                    v-for="(image, key) in imagesArray"
                    :key="key"
                    class="p-3"
                  >
                    <button
                      style="color:white"
                      class="close ml-1"
                      @click.prevent="removeImage(image)"
                    >
                      &times;
                    </button>
                    <b-img
                      style="height: 150px;max-width: 165px;"
                      thumbnail
                      fluid
                      :src="getLogo(image)"
                      alt="Image 1"
                    ></b-img>
                  </b-col-4>
                </b-row> </b-container
              ><br /><br />

              <b-button ref="save" type="submit" variant="primary"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      startDate: new Date(),
      selected: null,
      skipSharePercentage: 0,
      value: null,
      options: ["list", "of", "options"],
      user: null,
      users: [],
      coverimageUrl: null,
      coverImage: null,
      imagesArray: [],
      imagesShow: false,
      expirydate: "",
      description: "",
      instruction: "",
      alertText: "",
      alert: false,
      space: null,
      spaceNull: null,
      price: "",
      zipcode: "",
      zipcodeValidation: true,
      size: null,
      sizes: [{ text: "Select size", value: null }, "Small", "Medium", "Large"],
      spaces: [
        { text: "Select space", value: null },
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60",
        "65",
        "70",
        "75",
        "80",
        "85",
        "90",
        "95",
        "100",
      ],
      show: true,
    };
  },

  mounted() {
    this.getAllCustomer();
    this.skipPercentage();
    // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // this.skipSharePercentage = currentUser.skip_share_percentage;
    // this.getAllCustomer()
  },
  methods: {
    isZipCodeValid: function isZipCodeValid() {
      var regex1 = /^([A-Z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|[Gg][Ii][Rr] ?0[A]{2})$/;
      var responcend = regex1.test(this.zipcode);
      if (responcend) {
        this.zipcodeValidation = true;
      } else {
        this.zipcodeValidation = false;
      }
      return responcend;
    },

    formatNumber(e) {
      return String(e).substring(0);
    },

    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.price.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.price != null &&
        this.price.indexOf(".") > -1 &&
        this.price.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },
    onSubmit(evt) {
      // let sum = 0;
      // let skipSharePercentageCalculation = parseFloat(
      //   ((this.price / 100) * this.skipSharePercentage).toFixed(2)
      // );
      // sum +=
      //   parseFloat(skipSharePercentageCalculation) + parseFloat(this.price);
      try {
        const formData = new FormData();
        if (!this.zipcodeValidation) {
          evt.preventDefault();
          return false;
        }
        if (this.selected == null) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Please select customer";
          return false;
        }
        if (this.expirydate == "") {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Expiry Date is required";
          return false;
        }
        if (this.imagesArray.length < 2) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "At Least 2 images are mandatory";
          return false;
        }
        this.addLoaderClasses("save");
        formData.append("size", this.size);
        formData.append("user_id", this.selected.id);
        formData.append("space", this.space);
        formData.append("zipcode", this.zipcode);
        formData.append("price", this.price);
        formData.append("description", this.description);
        formData.append("instruction", this.instruction);
        formData.append("expiry_date", this.expirydate);
        formData.append("images", JSON.stringify(this.imagesArray));
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }

        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-share-store", formData, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              localStorage.setItem(
                "alertmessage",
                JSON.stringify("Record has been added successfully")
              );
              this.$router.push({ name: "skipshare" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.imagesArray = response.data.data;
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },
    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },

    skipPercentage() {
      try {
        axios
          .get(this.$path + "/skip-share-percentage-list")
          .then((response) => {
            this.skipSharePercentage = response.data.data[0].percentage;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
</style>
